<template>
    <div class="profile-header">
        <profile-picture :user-id="userId"></profile-picture>
        <div class="username">
            <h1 class="username-heading">
                {{ username }}
            </h1>
            <div class="subtext">
                <a :href="userProfileLink" target="_blank" v-if="showAnirecLink">View Full List and Statistics</a>
                <a :href="userProfileLink" target="_blank" v-if="!showAnirecLink">Visit this user on MyAnimeList</a>
            </div>
            <sync-list-button :username="username"></sync-list-button>
        </div>
    </div>
</template>

<script>
import ProfilePicture from './profile-picture';
import SyncListButton from '../sync-list-button';

export default {
    name: 'ProfileHeader',
    props: ['username', 'userId', 'showAnirecLink'],
    components: { ProfilePicture, SyncListButton },
    computed: {
        userProfileLink () {
            return this.showAnirecLink ? `/app/user/${this.username}` : `https://myanimelist.net/profile/${this.username}`;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.profile-header {
    display: flex;
    flex-direction: column;

    @include tablet() {
        flex-direction: row;
    };

    @include phone() {
        flex-direction: row;
    };
}

.profile-picture {
    margin-bottom: 20px;

    @include tablet() {
        margin-right: 30px;
    };

    @include phone() {
        margin-right: 30px;
    };
}


.username {
    @include tablet() {
        margin-right: 60px;
    };

    @include phone() {
        margin-right: 60px;
    };

    .username-heading {
        font-size: 40px;
        margin-top: 0;
        word-break: break-all;
        margin-bottom: 15px;
        line-height: 1em;
    }

    .subtext {
        margin-bottom: 15px;
    }

    .sync-list-button {
        margin-bottom: 30px;
    }
}
</style>
