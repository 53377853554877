<template>
    <div class="most-compatible-users">
        <h3 class="title">Most compatible users</h3>
        <a class="subtext" @click="showExplanation = !showExplanation">
            Lower scores are better
        </a>
        <div v-if="showExplanation" class="explanation">
            Matches are determined on a <span class="italic">per user</span> basis, based on a number of factors, most notably ratings similarity.
            Additionally, matches are sometimes one-way; users are not necessarily on each other's lists.
            This is based on the idea that, while a user who is very well-versed in anime or manga may be able to
            recommend titles to a newcomer, the reverse is not true.
        </div>

        <div class="high-compat-list" v-if="compatibleUsersList.length">
            <div class="compat-user"
                v-for="compatEntry in compatibleUsersList"
                :key="compatEntry.foreignUsername">
                <div class="top-row">
                    <profile-picture :user-id="compatEntry.foreignUser" small="true"></profile-picture>
                    <div class="user-info">
                        <a class="username" :href="`/app/user/${compatEntry.foreignUsername}`">{{ compatEntry.foreignUsername }}</a>
                        <a class="profile-link"
                            :href="`https://myanimelist.net/profile/${compatEntry.foreignUsername}`"
                            target="_blank">
                            Visit user on MyAnimeList
                        </a>
                    </div>
                </div>
                <div class="user-stats">
                    <div class="stat">
                        Compatibility score: <span class="bold">{{ compatEntry.compatibility.toFixed(0) }}</span>
                    </div>
                    <div class="stat">
                        Anime in common: <span class="bold">{{ compatEntry.animeInCommon === null ? 'Not synced' : compatEntry.animeInCommon }}</span>
                    </div>
                    <div class="stat">
                        Manga in common: <span class="bold">{{ compatEntry.mangaInCommon === null ? 'Not synced' : compatEntry.mangaInCommon }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="no-compat-users" v-if="!compatibleUsersList.length">
            No data available for this user (they probably haven't synced their account)
        </div>
    </div>
</template>

<script>
import MaterialButton from 'vue-material-button';
import ProfilePicture from './profile-picture';

export default {
    name: 'MostCompatibleUsers',
    props: ['username', 'compatibleUsers'],
    components: { ProfilePicture, MaterialButton },
    data () {
        return {
            showExplanation: false,
        };
    },
    computed: {
        compatibleUsersList () {
            return this.compatibleUsers.list;
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../mixins/all.scss';

.title {
    margin-bottom: 3px;
    font-weight: bold;
    font-size: 20px;
}

.subtext {
    font-size: 16px;
    margin-bottom: 10px;
    font-style: italic;
    display: inline-block;
    cursor: pointer;
}

.explanation {
    margin-left: 10px;
    margin-bottom: 20px;
}

.high-compat-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.compat-user {
    background-color: lighten($isabelline, 3);
    box-sizing: border-box;
    color: $olive-black;
    margin-bottom: 10px;
    margin-right: 10px;
    min-height: 36px;
    padding: 7px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    width: 260px;

    .top-row {
        display: flex;
        flex-direction: row;
    }

    .profile-picture {
        margin-right: 10px;
    }

    .user-info {
        overflow: hidden;
    }

    .username {
        font-weight: bold;
        font-size: 20px;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            text-decoration: none;
        }
    }

    .profile-link {
        display: inline-block;
        margin-top: 3px;
        font-size: 14px;
    }

    .user-stats {
        display: flex;
        flex-direction: column;
        font-size: 14px;
        color: transparentize($olive-black, 0.4);

        .stat {
            margin-top: 3px;
        }
    }
}
</style>
